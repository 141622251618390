<template>
  <v-app-bar
    :class="{
      'app-bar-mobile-1': isSmall && isHome,
      'app-bar-mobile-2': isSmall && !isHome,
      'app-bar-mobile-3': isSmall && (isBoozeDetailPrice || isBoozeDetail),
    }"
    color="white"
    elevation="1"
    fixed
  >
    <router-link @click="activeTag === '' ? '' : scrollToTrending()" to="/">
      <div class="logo-img-container d-flex align-center">
        <v-img
          class="logo-img"
          :src="$fileURL + logo"
          height="50"
          :class="{ 'ml-8': isWelcome }"
        >
          <template #placeholder>
            <div class="skeleton" />
          </template>
        </v-img>
      </div>
    </router-link>
    <div v-if="isWelcome" class="ml-10 d-flex flex-row header-info">
      <div v-if="!isSmall" class="divider" />
      <span :class="{ 'header-info-span': isSmall }">Sign Up / Login</span>
    </div>
    <v-spacer v-if="isWelcome" />
    <form v-if="!isWelcome" class="navbar__search navbar__search__desktop">
      <input
        id="product_name"
        class="form-control mr-sm-2"
        type="text"
        style="font-style: italic"
        placeholder="Type your brands... Chivas , Monkey , Roku , Glennfidich"
        aria-label="Search"
        data-autocompleturl="https://boozards.com/merchant-product/search"
      />
      <button class="btn btn--search" type="submit">
        <v-icon color="white"> mdi-magnify </v-icon>
      </button>
    </form>
    <div v-if="!isWelcome && !isSmall" class="desktop__app">
      <v-menu>
        <template #activator="{ props }">
          <v-btn
            style="
              margin-left: 30px;
              margin-right: 30px;
              font-size: 16px;
              color: #494949;
            "
            v-bind="props"
            variant="text"
          >
            {{ itemSelected }}
            <v-icon right dark> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="(item, index) in city"
            :key="index"
            :value="index"
            @click="changeItemSelected(item.title)"
          >
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-btn v-if="!isWelcome" elevation="0" class="btn_sign__up" to="/welcome">
      Sign up / Register
    </v-btn>
    <div v-if="!isSmall" class="cart d-flex align-center">
      <div class="cart-line mr-2" />
      <v-icon size="35" color="black"> mdi mdi-cart-variant </v-icon>
      <span>S$ 0</span>
    </div>
    <v-btn
      style="background: #ffa42e; border-radius: 0 !important"
      v-if="!isWelcome"
      icon
      @click="drawer = !drawer"
    >
      <v-icon size="35" color="#fff"> mdi mdi-menu </v-icon>
    </v-btn>

    <template v-if="!isWelcome" #extension>
      <div
        v-if="isSmall"
        class="text-center scroll-container d-flex flex-column justify-center align-content-space-between mx-2"
        :class="{ 'mb-n10': !isHome }"
      >
        <div v-if="isHome">
          <v-menu>
            <template #activator="{ props }">
              <v-btn
                style="
                  margin-left: 30px;
                  margin-right: 30px;
                  font-size: 16px;
                  color: #494949;
                "
                v-bind="props"
                variant="text"
              >
                {{ itemSelected }}
                <v-icon right dark> mdi-menu-down </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(item, index) in city"
                :key="index"
                :value="index"
                @click="changeItemSelected(item.title)"
              >
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <form
          v-if="isHome"
          class="navbar__search navbar__search__mobile mx-auto"
          @submit="preventSubmit"
        >
          <input
            id="product_name"
            class="form-control mr-sm-2"
            type="text"
            placeholder="Type your brands... Chivas , Monkey , Roku , Glennfidich"
            aria-label="Search"
            data-autocompleturl="https://boozards.com/merchant-product/search"
          />
          <button class="btn btn--search" type="submit">
            <v-icon color="white"> mdi-magnify </v-icon>
          </button>
        </form>
        <div
          style="width: 300px !important; overflow-y: hidden !important"
          class="w-100"
          v-if="isBoozeDetail"
        >
          <h3>Our Whisky</h3>
          <v-autocomplete
            density="compact"
            variant="outlined"
            v-model="brand"
            label="---Select Brand---"
            :items="resource.brands"
            clearable
          ></v-autocomplete>
        </div>
        <div v-if="isBoozeDetailPrice" class="w-100">
          <div
            class="d-flex align-center justify-center mx-auto"
            style="gap: 5px"
          >
            <div>
              <v-img
                src="@/assets/image/brand1.png"
                width="70"
                height="50"
                cover
              >
                <template #placeholder>
                  <div class="skeleton" />
                </template>
              </v-img>
            </div>
            <div class="d-flex flex-column justify-start">
              <h4>Monkey Shoulders</h4>
              <p class="text-grey">Whisky from Scotland</p>
            </div>
          </div>
          <div class="my-slide d-flex mt-2">
            <v-btn
              class="sub-menu-btn view-all"
              :class="{
                active: isSelected,
              }"
              style="box-shadow: 0 5px 25px rgba(0, 0, 0, 0)"
              @click="selectTag('')"
            >
              <p style="font-size: 12px" elevation>View All</p>
              <!-- <span class="badge" :class="isSelected ? 'active' : ''"
            >2.7K</span
          > -->
            </v-btn>
            <v-slide-group v-model="activeTag">
              <v-slide-group-item
                v-for="btn in trendingBtn"
                :key="btn.tag"
                v-slot="{ isSelected }"
                :value="btn.tag"
              >
                <!-- :to="`/${btn.id}`" -->
                <v-btn
                  @click="selectTag(btn.tag)"
                  class="sub-menu-btn"
                  :class="{
                    active: isSelected,
                  }"
                  style="box-shadow: 0 5px 25px rgba(0, 0, 0, 0)"
                >
                  <p style="font-size: 12px" elevation>
                    {{ btn.title }}
                  </p>
                  <!-- <span class="badge" :class="isSelected ? 'active' : ''"
                >2.7K</span
              > -->
                </v-btn>
              </v-slide-group-item>
            </v-slide-group>
          </div>
        </div>
        <div v-if="isHome" class="my-slide d-flex">
          <v-btn
            class="sub-menu-btn view-all"
            :class="{
              active: isSelected,
            }"
            style="box-shadow: 0 5px 25px rgba(0, 0, 0, 0)"
            @click="selectTag('')"
          >
            <p style="font-size: 12px" elevation>View All</p>
            <!-- <span class="badge" :class="isSelected ? 'active' : ''"
            >2.7K</span
          > -->
          </v-btn>
          <v-slide-group v-model="activeTag">
            <v-slide-group-item
              v-for="btn in trendingBtn"
              :key="btn.tag"
              v-slot="{ isSelected }"
              :value="btn.tag"
            >
              <!-- :to="`/${btn.id}`" -->
              <v-btn
                @click="selectTag(btn.tag)"
                class="sub-menu-btn"
                :class="{
                  active: isSelected,
                }"
                style="box-shadow: 0 5px 25px rgba(0, 0, 0, 0)"
              >
                <p style="font-size: 12px" elevation>
                  {{ btn.title }}
                </p>
                <!-- <span class="badge" :class="isSelected ? 'active' : ''"
                >2.7K</span
              > -->
              </v-btn>
            </v-slide-group-item>
          </v-slide-group>
        </div>
      </div>
    </template>
  </v-app-bar>
  <v-navigation-drawer
    v-if="!isWelcome || (isWelcome && isSmall)"
    v-model="drawer"
    temporary
    location="right"
  >
    <div class="drawer__top">
      <a style="font-size: 1.125rem; color: white">Sign up / Register</a>
    </div>
    <ul class="v-list-cont d-flex mt-4" nav dense>
      <li class="v-list-item">
        <router-link
          class="text-decoration-none text-black"
          @click="activeTag === '' ? '' : scrollToTrending()"
          to="/"
        >
          <v-list-item-title>Home</v-list-item-title>
        </router-link>
      </li>

      <li class="v-list-item">
        <v-list-item-title>Employer</v-list-item-title>
      </li>

      <li class="v-list-item">
        <v-list-item-title>Agency</v-list-item-title>
      </li>
    </ul>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import app from '@/util/eventBus';
import axios from '@/util/axios';

// import eventBus from "@/util/eventBus";
// import eventBus from "@/util/eventBus";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  props: ['isWelcome'],
  data() {
    return {
      // selectedTag: null,
      trendingBtn: [],
      drawer: false,
      // itemSelected: 'Singapore',
      city: [],
      logo: '',
      brand: null,
      resource: {
        brands: [],
      },
      selectedType: 0,
      activeIndex: 1,
      screenWidth: window.innerWidth,
    };
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    brand: function (newBrand, oldBrand) {
      this.setActiveTag(newBrand);
      this.$store.commit('setActiveTag', newBrand);
    },
  },
  computed: {
    ...mapState(['itemSelected', 'ativeTag']),
    isSmall() {
      return this.screenWidth < 640;
    },
    isHome() {
      return this.$route.path === '/' || this.$route.path === '/ourbooze';
    },
    isBoozeDetail() {
      return this.$route.path === `/ourbooze/${this.$route.params.name}`;
    },
    isBoozeDetailPrice() {
      return this.$route.path === `/${this.$route.params.name}`;
    },
    ...mapState(['activeTag']),
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },

  mounted() {
    this.getLogo();
    this.getCity();
    this.getCategoryCardData();
    // if (this.isBoozeDetail) {
    //   this.getBrandsCardData();
    // }
    app.config.globalProperties.$eventBus.$on(
      'getDetailCard',
      this.getBrandsCardData
    );
    app.config.globalProperties.$eventBus.$on(
      'getDetailPriceCard',
      this.getBrandsPriceCardData
    );
  },
  beforeUnmount() {
    app.config.globalProperties.$eventBus.$off(
      'getDetailCard',
      this.getBrandsCardData
    );
    app.config.globalProperties.$eventBus.$off(
      'getDetailPriceCard',
      this.getBrandsPriceCardData
    );
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    changeItemSelected(item) {
      this.$store.commit('setItemSelected', item);
    },
    ...mapMutations(['setActiveTag']),
    // inputFilter() {
    //   this.setActiveTag(this.brand);
    // },
    selectTag(tag) {
      // this.setActiveTag(tag); // Menetapkan tag yang dipilih sebagai tag aktif
      this.$store.commit('setActiveTag', tag);

      app.config.globalProperties.$eventBus.$emit('scrollToCardSection');
    },
    scrollToTrending() {
      app.config.globalProperties.$eventBus.$emit('scrollToCardSection');
    },
    getCategoryCardData() {
      this.isLoading = true;
      axios
        .get(`/categories/${this.$appId}`)
        .then((response) => {
          const data = response.data.data;
          this.trendingCard = data.map((item, index) => {
            return {
              id: index + 1,
              title: item.category_name || '',
              tag: item.category_name || '',
            };
          });
          this.trendingBtn = data.map((item) => {
            return {
              title: item.category_name || '',
              tag: item.category_name || '',
            };
          });
          // console.log(this.trendingCard);

          // app.config.globalProperties.$eventBus.$emit(
          //   'update-image',
          //   this.items
          // );
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getLogo() {
      axios
        .get(`/app/logo/${this.$appId}`)
        .then((response) => {
          const data = response.data.data;
          // console.log(data);
          this.logo = data;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getCity() {
      axios
        .get(`/city`)
        .then((response) => {
          const data = response.data.data;
          // console.log(data);
          this.city = data.map((city) => {
            return {
              id: city.city_id,
              title: city.city_name,
              path: '#',
            };
          });
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    },
    getBrandsPriceCardData() {
      this.trendingBtn = [
        {
          title: 'Bottle Shop',
          tag: 'Bottle Shop',
        },
        {
          title: 'Super Market',
          tag: 'Super Market',
        },
        {
          title: 'Mini Mart',
          tag: 'Mini Mart',
        },
      ];
    },
    getBrandsCardData() {
      this.brand = null;
      const slug = this.$route.params.name;
      this.isLoading = true;
      axios
        .get(`/brands-products/category/${slug}`)
        .then((response) => {
          const data = response.data.data;
          this.resource.brands = data.map((item) => item.brand_name);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    // emitFilterEvent(tag) {
    //   this.$emit("filter-card", tag);
    // },
    // filterCards(tag) {
    //   this.selectedTag = tag;
    //   app.config.globalProperties.$eventBus.$emit("filter-card-header", tag);
    //   // eventBus.emit("filter-card-header", tag);
    // },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    previousSlide() {
      this.activeIndex--;
    },
    nextSlide() {
      this.activeIndex++;
    },
    preventSubmit(event) {
      event.preventDefault();
    },
  },
};
</script>

<style scoped>
.view-all {
  position: sticky !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 1000 !important;
  /* Gaya view all yang sticky */
}
.scroll-container {
  margin-top: -80px;
  overflow-x: auto;
  white-space: nowrap;
  gap: 20px;
}
.v-app-bar.v-toolbar {
  max-width: 100%;
}
.app-bar-mobile-1 {
  height: 29vh;
}
.app-bar-mobile-2 {
  height: 17vh;
}
.app-bar-mobile-3 {
  height: 22vh;
}

.divider {
  background: rgb(173, 173, 173);
  width: 2px;
  height: 70px;
}

.header-info {
  align-items: center;
  gap: 25px;
  font-size: 30px;
  color: black;
  font-weight: 800;
}

.header-info-span {
  font-size: 25px;
  font-weight: 800;
}

.logo-img-container {
  height: 80px;
  min-width: 100px;
}

.logo-img {
  width: 100%;
  height: 100%;
}

.cart {
  width: 100px;
}

.cart:hover {
  cursor: pointer;
}

.cart-line {
  height: 25px;
  width: 1px;
  background: black;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;
  z-index: 10000;
  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

.v-list-cont {
  flex-direction: column;
  gap: 20px;
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
