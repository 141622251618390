<template>
  <div>
    <div v-if="isLoading" class="text-center loading-page">
      <v-progress-circular :size="50" color="#fa2964" indeterminate />
    </div>
    <template v-if="!isLoading">
      <v-container :class="{ 'mt-16 pt-10': isSmall, 'mt-16': !isSmall }">
        <div
          v-if="!isSmall"
          class="d-flex mt-10 align-center justify-center"
          style="gap: 30px"
        >
          <div class="img-cont mt-4">
            <v-img :src="itemData?.image" height="200" cover>
              <template #placeholder>
                <div class="skeleton" />
              </template>
            </v-img>
          </div>
          <div>
            <h1>
              {{ itemData?.brand }}
            </h1>
            <p class="text-grey">{{ itemData?.category }} from Scotland</p>
          </div>
        </div>
        <hr v-if="!isSmall" class="my-10" />
        <!-- <p class="header-title-sub" style="margin-top: 32px">
        The gypsy Trending Web Apps
      </p> -->
      </v-container>

      <v-container
        id="trending"
        class="wrapper-box"
        :class="{ 'mt-16': isSmall }"
      >
        <template v-if="isSmall">
          <div
            v-for="product in itemData?.products"
            :key="product.id"
            class="trending__app__wrapper mb-2"
          >
            <div class="w-100 pl-3 pr-10 section-head">
              <h4>{{ `${product.product} ${product.quantity}` }}</h4>
              <p style="font-size: 12px" class="text-grey">
                <span>Lowest Price </span>
                <span class="text-red">S$ 64.50</span> |

                <span>Highest Price </span>
                <span class="text-blue">S$ 72.80</span>
              </p>
            </div>
            <v-sheet class="mx-auto" elevation="0">
              <v-slide-group v-model="model" class="pa-4">
                <v-slide-group-item v-for="(card, i) in 8" :key="i">
                  <div class="trending__app d-flex justify-center mb-8">
                    <v-card
                      style="border-radius: 0px"
                      class="mr-6"
                      width="190"
                      height="150"
                    >
                      <v-card-title>
                        <div class="d-flex justify-space-between align-center">
                          <span
                            style="font-size: 13px"
                            class="text-red-darken-4"
                            >S$ 64.50</span
                          >
                          <v-btn
                            height="23"
                            width="80"
                            class="text-black elevation-2"
                            style="
                              background-color: rgb(255, 221, 0);
                              border-color: rgb(255, 221, 0);
                              font-size: 11px;
                              font-weight: 400;
                            "
                            elevation="2"
                          >
                            <span>Add To Cart</span>
                          </v-btn>
                        </div>
                      </v-card-title>
                      <hr />
                      <v-card-text>
                        <p class="font-weight-bold">Tamil Naidu Wines</p>
                        <p class="text-blue">Little India</p>
                        <p>
                          <span class="text-red">8.93</span
                          ><span class="text-grey"> kms away</span>
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                  <!-- </v-lazy> -->
                </v-slide-group-item>
                <!-- </transition-group> -->
              </v-slide-group>
            </v-sheet>
          </div>
          <hr v-if="itemData?.products.length > 1" />
        </template>
        <template v-if="!isSmall">
          <v-row
            v-for="product in itemData?.products"
            :key="product.id"
            class="trending__app__wrapper mb-2"
          >
            <div class="w-100 pl-3 pr-10 section-head">
              <h2>{{ `${product.product} ${product.quantity}` }}</h2>
              <p class="text-grey">
                <span>Lowest Price </span>
                <span>S$ 64.50</span> |

                <span>Highest Price </span>
                <span>S$ 72.80</span>
              </p>
            </div>
            <transition-group name="card-transition" mode="out-in">
              <v-col v-for="(card, i) in 4" :key="i" cols="3" class="card mt-6">
                <v-lazy :options="{ threshold: 0.5 }" min-height="250">
                  <div
                    class="trending__app d-flex flex-column justify-center mb-8"
                  >
                    <v-card
                      style="
                        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
                        border-radius: 10px;
                      "
                      width="250"
                      height="150"
                      elevation="1"
                    >
                      <v-card-title>
                        <div class="d-flex justify-space-between align-center">
                          <span class="text-red-darken-4">S$ 64.50</span>
                          <v-btn
                            height="23"
                            width="80"
                            class="text-black elevation-2"
                            style="
                              background-color: rgb(255, 221, 0);
                              border-color: rgb(255, 221, 0);
                              font-size: 11px;
                              font-weight: 400;
                            "
                            elevation="2"
                          >
                            <span>Add To Cart</span>
                          </v-btn>
                        </div>
                      </v-card-title>
                      <hr />
                      <v-card-text>
                        <p class="font-weight-bold">Tamil Naidu Wines</p>
                        <p class="text-blue">Little India</p>
                        <p>
                          <span class="text-red">8.93</span
                          ><span class="text-grey"> kms away</span>
                        </p>
                      </v-card-text>
                    </v-card>
                  </div>
                </v-lazy>
              </v-col>
            </transition-group>
          </v-row>
        </template>
      </v-container>
    </template>
  </div>
</template>

<script>
import axios from '@/util/axios';
import app from '@/util/eventBus';
import { mapState } from 'vuex';

export default {
  name: 'OurBoozeDetailPrice',
  data() {
    return {
      isLoading: false,
      selectedTag: null,
      brandsCard: [],
      itemData: null,
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState(['activeTag', 'slugSelected']),
    isSmall() {
      return this.screenWidth < 640;
    },
    // filteredItemsMobile() {
    //   // console.log(this.activeTag);
    //   if (!this.activeTag || this.activeTag == undefined) {
    //     return this.categoryCard;
    //   } else {
    //     // const searchTextLower = this.search.toLowerCase();
    //     const filtered = this.categoryCard.filter((item) => {
    //       return item.title
    //         .toLowerCase()
    //         .includes(this.activeTag.toLowerCase());
    //     });

    //     return filtered;
    //   }
    // },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.getBrandsCardData();
    this.getDetailPriceCard();
    app.config.globalProperties.$eventBus.$on(
      'scrollToCardSection',
      this.scrollToCardSection
    );
  },
  beforeUnmount() {
    app.config.globalProperties.$eventBus.$off(
      'scrollToCardSection',
      this.scrollToCardSection
    );
    // eventBus.off("filter-card-header", this.filterCards);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getDetailPriceCard() {
      app.config.globalProperties.$eventBus.$emit('getDetailPriceCard');
    },
    scrollToCardSection() {
      const cardSection = document.getElementById('trending');
      const cardRect = cardSection.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const offset = cardRect.top + scrollTop - 350; // Nilai offset yang diinginkan, dalam piksel

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
      // window.scrollBy(0, -scrollOffset);
    },

    getBrandsCardData() {
      const slug = this.$route.params.name;
      const name = slug.split('-').join(' ');
      this.isLoading = true;
      axios
        .get(`/brands-products/category/${this.slugSelected}`)
        .then((response) => {
          const data = response.data.data;
          this.itemData = data
            .filter((i) => i.brand_name.toLowerCase() == name)
            .map((item) => {
              return {
                id: item.brand_id || 1,
                brand: item.brand_name || '',
                category: item.category_name || '',
                image: this.$fileURL + item.image || '',
                path: item.brand_name.split(' ').join('-').toLowerCase() || '',
                products: item.products.map((product) => {
                  return {
                    id: product.product_id || '',
                    product: product.product_name || '',
                    quantity: product.quantity[0]?.quantity_name || '',
                  };
                }),
              };
            })[0];
          console.log(this.itemData);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.line-divider {
  height: 2px;
  width: 150px;
  background: #ffa42e;
}

.title-card {
  position: absolute;
  top: -20px;
  width: 80%;
  color: white;
  background-color: #ffa42e;
  padding: 10px auto;
  z-index: 100;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border-radius: 30px;
}
.title-card-mobile {
  width: 70%;
  font-size: 12px !important;
}

.card-desc-cont h2 {
  color: white;
}
.card-desc-cont p {
  color: white;
  margin-bottom: 10px;
}
.card-desc-cont-mobile h2 {
  font-size: 16px;
  color: white;
}
.card-desc-cont-mobile p {
  font-size: 12px;
  color: white;
  margin-bottom: 10px;
}
.my-slide {
  position: relative !important;
}
.view-all {
  background: #0596d5;
  color: white;
  height: 50px !important;
  z-index: 100 !important;
  /* Gaya view all yang sticky */
}
.card-transition-enter-active,
.card-transition-leave-active {
  transition: transform 0.5s, opacity 0.3s;
}

.card-transition-enter {
  opacity: 0;
  transform: translateX(-50%);
}

.card-transition-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.img-item {
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  transform: scale(1);
}

.img-cont {
  position: relative;
}
.img-cont:hover .title-card {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100;
}

.img-cont:hover .overlay {
  opacity: 1;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;

  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

.desktop-card-desc {
  height: 70px !important;
}

.btn-section {
  background-color: #ffa42e;
  border-color: #ffa42e;
  font-weight: 400;
  font-size: 20px;
  border-radius: 50px;
}
.btn-section-2 {
  font-size: 14px;
}

.loading-page {
  height: 100vh;
  margin-top: 400px;
}

@media (max-width: 599px) {
  .loading-page {
    margin-top: 450px;
  }
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
