<template>
  <div>
    <div v-if="isLoading" class="text-center loading-page">
      <v-progress-circular :size="50" color="#fa2964" indeterminate />
    </div>
    <template v-if="!isLoading">
      <v-container :class="{ 'mt-8': isSmall }">
        <div id="trending-section" class="jumbotron-text">
          <h1
            align="center"
            class="header-title mb-n10 mt-16"
            style="font-size: 56px; font-style: normal; font-weight: 700"
          >
            OUR BRANDS
          </h1>
          <div class="line-divider mt-10 mx-auto" />
          <!-- <p class="header-title-sub" style="margin-top: 32px">
        The gypsy Trending Web Apps
      </p> -->
        </div>
      </v-container>

      <v-container
        id="trending"
        class="wrapper-box"
        :class="{ 'mt-n10': isSmall }"
      >
        <template v-if="isSmall">
          <div
            v-for="(item, i) in brandsCard"
            :key="i"
            class="trending__app__wrapper mb-2"
          >
            <div
              class="w-100 d-flex justify-space-between pl-3 pr-4 section-head"
            >
              <h3>
                {{ item.category }}
              </h3>
              <router-link
                :to="`/ourbrands-${item.slug.toLowerCase()}`"
                class="text-decoration-none"
              >
                <h3>View all</h3>
              </router-link>
            </div>
            <v-sheet class="mx-auto" elevation="0">
              <v-slide-group v-model="model" class="pa-4">
                <!-- <transition-group name="card-transition" mode="out-in"> -->
                <v-slide-group-item v-for="card in item.list" :key="card.id">
                  <!-- <v-lazy :options="{ threshold: 0.5 }" min-height="200"> -->
                  <div class="trending__app d-flex justify-center mb-8">
                    <!-- <div class="title-card title-card-mobile py-2 mx-auto">
                        <h1>{{ card.title }}</h1>
                      </div> -->
                    <v-card
                      style="border-radius: 0px"
                      class="mr-6"
                      width="230"
                      height="230"
                    >
                      <h4 class="text-blue-darken-4 mb-2">{{ card.title }}</h4>
                      <div class="img-cont">
                        <div class="cart clearfix animate-effect">
                          <div class="action">
                            <div class="card-desc-cont-mobile px-2 text-center">
                              <h2>{{ card.title }}</h2>
                              <p>{{ card.desc }}</p>
                              <v-btn
                                elevation="4"
                                style="
                                  background-color: #ffa42e;
                                  border-radius: 0;
                                  padding-left: 6px;
                                  padding-right: 6px;
                                  padding-top: 4px;
                                  padding-bottom: 4px;
                                  font-weight: 600;
                                  font-size: 12px;
                                "
                              >
                                <span class="text-black" style=""
                                  >VIEW PRICES</span
                                >
                              </v-btn>
                            </div>
                          </div>
                        </div>
                        <div class="overlay"></div>
                        <v-img
                          :src="card.img"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="230"
                          cover
                          class="img-item"
                        >
                          <template #placeholder>
                            <div class="skeleton" />
                          </template>
                        </v-img>
                      </div>
                    </v-card>
                  </div>
                  <!-- </v-lazy> -->
                </v-slide-group-item>
                <!-- </transition-group> -->
              </v-slide-group>
            </v-sheet>
          </div>
        </template>
        <template v-if="!isSmall">
          <v-row
            v-for="(item, i) in brandsCard"
            :key="i"
            class="trending__app__wrapper mb-2"
          >
            <div
              class="w-100 d-flex justify-space-between pl-3 pr-10 section-head"
            >
              <h1>
                {{ item.category }}
              </h1>
              <router-link
                :to="`/ourbrands-${item.slug.toLowerCase()}`"
                class="text-decoration-none"
              >
                <h1>View all</h1>
              </router-link>
            </div>
            <transition-group name="card-transition" mode="out-in">
              <v-col
                v-for="(card, i) in item.list.slice(0, 3)"
                :key="i"
                xs="6"
                sm="6"
                md="4"
                cols="12"
                class="card"
              >
                <v-lazy :options="{ threshold: 0.5 }" min-height="250">
                  <div
                    class="trending__app d-flex flex-column justify-center mb-8"
                  >
                    <h3 class="text-blue-darken-4 mb-2">{{ card.title }}</h3>
                    <v-card
                      style="
                        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
                        border-radius: 10px;
                      "
                      width="350"
                      height="250"
                    >
                      <div class="img-cont">
                        <div class="cart clearfix animate-effect">
                          <div class="action">
                            <div class="card-desc-cont px-2 text-center">
                              <h2>{{ card.title }}</h2>
                              <p>{{ card.desc }}</p>
                              <v-btn
                                elevation="4"
                                style="
                                  background-color: #ffa42e;
                                  border-radius: 0;
                                  padding-left: 16px;
                                  padding-right: 16px;
                                  padding-top: 10px;
                                  padding-bottom: 10px;
                                "
                              >
                                <span class="text-black" style=""
                                  >VIEW PRICES</span
                                >
                              </v-btn>
                            </div>
                          </div>
                        </div>
                        <div class="overlay"></div>
                        <v-img
                          :src="card.img"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="250"
                          cover
                          class="img-item"
                        >
                          <template #placeholder>
                            <div class="skeleton" />
                          </template>
                        </v-img>
                      </div>
                    </v-card>
                  </div>
                </v-lazy>
              </v-col>
            </transition-group>
          </v-row>
        </template>
      </v-container>
    </template>
  </div>
</template>

<script>
import axios from '@/util/axios';
import app from '@/util/eventBus';
import { mapState } from 'vuex';

export default {
  name: 'OurBrandsDetail',
  data() {
    return {
      isLoading: false,
      selectedTag: null,
      brandsCard: [],
      screenWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapState(['activeTag']),
    isSmall() {
      return this.screenWidth < 640;
    },
    // filteredItemsMobile() {
    //   // console.log(this.activeTag);
    //   if (!this.activeTag || this.activeTag == undefined) {
    //     return this.categoryCard;
    //   } else {
    //     // const searchTextLower = this.search.toLowerCase();
    //     const filtered = this.categoryCard.filter((item) => {
    //       return item.title
    //         .toLowerCase()
    //         .includes(this.activeTag.toLowerCase());
    //     });

    //     return filtered;
    //   }
    // },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.getBrandsCardData();
    app.config.globalProperties.$eventBus.$on(
      'scrollToCardSection',
      this.scrollToCardSection
    );
  },
  beforeUnmount() {
    app.config.globalProperties.$eventBus.$off(
      'scrollToCardSection',
      this.scrollToCardSection
    );
    // eventBus.off("filter-card-header", this.filterCards);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    scrollToCardSection() {
      const cardSection = document.getElementById('trending');
      const cardRect = cardSection.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const offset = cardRect.top + scrollTop - 350; // Nilai offset yang diinginkan, dalam piksel

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
      // window.scrollBy(0, -scrollOffset);
    },
    getBrandsCardData() {
      this.isLoading = true;
      axios
        .get(`/brands/${this.$appId}`)
        .then((response) => {
          const data = response.data.data;
          this.brandsCard = data.reduce((result, item) => {
            const { category_name } = item.category;

            // periksa apakah kategori sudah ada dalam hasil pengelompokkan
            const categoryIndex = result.findIndex(
              (category) => category.category === category_name
            );

            if (categoryIndex !== -1) {
              // kategori sudah ada, tambahkan brand ke dalam kategori yang sudah ada
              result[categoryIndex].list.push({
                id: item.brand_id || 1,
                img: this.$fileURL + item.image || '',
                desc: item.description || '',
                title: item.brand_name || '',
                // active: item.active === 'Y' ? true : false,
                // website: item.website === 'Y' ? true : false,
                // country: item.country.country_name,
              });
            } else {
              // kategori belum ada, tambahkan kategori baru beserta brand pertamanya
              result.push({
                category: category_name,
                slug:
                  category_name.split(' ').length > 1
                    ? category_name.split(' ').join('-')
                    : category_name,
                list: [
                  {
                    id: item.brand_id || 1,
                    img: this.$fileURL + item.image || '',
                    desc: item.description || '',
                    title: item.brand_name || '',
                    // active: item.active === 'Y' ? true : false,
                    // website: item.website === 'Y' ? true : false,
                    // country: item.country.country_name,
                  },
                ],
              });
            }

            return result;
          }, []);
          // console.log(groupedBrands);
          // console.log(this.trendingCard);

          // app.config.globalProperties.$eventBus.$emit(
          //   'update-image',
          //   this.items
          // );
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.line-divider {
  height: 2px;
  width: 150px;
  background: #ffa42e;
}

.title-card {
  position: absolute;
  top: -20px;
  width: 80%;
  color: white;
  background-color: #ffa42e;
  padding: 10px auto;
  z-index: 100;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border-radius: 30px;
}
.title-card-mobile {
  width: 70%;
  font-size: 12px !important;
}

.card-desc-cont h2 {
  color: white;
}
.card-desc-cont p {
  color: white;
  margin-bottom: 10px;
}
.card-desc-cont-mobile h2 {
  font-size: 16px;
  color: white;
}
.card-desc-cont-mobile p {
  font-size: 12px;
  color: white;
  margin-bottom: 10px;
}
.my-slide {
  position: relative !important;
}
.view-all {
  background: #0596d5;
  color: white;
  height: 50px !important;
  z-index: 100 !important;
  /* Gaya view all yang sticky */
}
.card-transition-enter-active,
.card-transition-leave-active {
  transition: transform 0.5s, opacity 0.3s;
}

.card-transition-enter {
  opacity: 0;
  transform: translateX(-50%);
}

.card-transition-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.img-item {
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  transform: scale(1);
}

.img-cont {
  position: relative;
}
.img-cont:hover .title-card {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100;
}

.img-cont:hover .overlay {
  opacity: 1;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;

  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

.desktop-card-desc {
  height: 70px !important;
}

.btn-section {
  background-color: #ffa42e;
  border-color: #ffa42e;
  font-weight: 400;
  font-size: 20px;
  border-radius: 50px;
}
.btn-section-2 {
  font-size: 14px;
}

.loading-page {
  height: 100vh;
  margin-top: 400px;
}

@media (max-width: 599px) {
  .loading-page {
    margin-top: 450px;
  }
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
