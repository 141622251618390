import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/HomeView.vue';
import OurBooze from '@/components/our booze/OurBooze.vue';
import OurBoozeDetail from '@/components/our booze/OurBoozeDetail.vue';
import OurBoozeDetailPrice from '@/components/our booze/OurBoozeDetailPrice.vue';
import OurBrandsDetail from '@/components/our brands/OurBrandsDetail.vue';
import OurBrandsSpecific from '@/components/our brands/OurBrandsSpecific.vue';

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    name: 'our booze',
    path: '/ourbooze',
    component: OurBooze,
  },
  {
    name: 'our booze detail',
    path: '/ourbooze/:name',
    component: OurBoozeDetail,
  },
  {
    name: 'our booze detail price',
    path: '/:name',
    component: OurBoozeDetailPrice,
  },

  {
    name: 'our brands',
    path: '/ourbrands',
    component: OurBrandsDetail,
  },
  {
    name: 'our brands specific',
    path: '/ourbrands-:name',
    component: OurBrandsSpecific,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
