<template>
  <div>
    <div v-if="isLoading" class="text-center loading-page">
      <v-progress-circular :size="50" color="#fa2964" indeterminate />
    </div>
    <template v-if="!isLoading">
      <v-container :class="{ 'mt-6': isSmall }">
        <div id="trending-section" class="jumbotron-text">
          <h1
            align="center"
            class="header-title mb-n4 mt-16"
            style="font-size: 56px; font-style: normal; font-weight: 700"
          >
            {{ title }}
          </h1>
          <!-- <p class="header-title-sub" style="margin-top: 32px">
            The gypsy Trending Web Apps
            </p> -->
        </div>
        <v-row class="d-flex justify-center">
          <v-col cols="12" md="3">
            <v-autocomplete
              density="compact"
              variant="outlined"
              v-model="brand"
              label="Type Brand"
              :items="resource.brand"
              clearable
            ></v-autocomplete>
          </v-col>
          <v-col cols="12" md="3">
            <v-autocomplete
              density="compact"
              variant="outlined"
              v-model="country"
              label="Type Country"
              :items="resource.country"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>

      <v-container id="trending" class="wrapper-box">
        <template v-if="isSmall">
          <v-row class="trending__app__wrapper mt-2">
            <transition-group name="card-transition" mode="out-in">
              <v-col v-for="(card, i) in filteredItems" :key="i" cols="12">
                <v-lazy :options="{ threshold: 0.5 }" min-height="200">
                  <div
                    class="trending__app d-flex flex-column justify-center mb-8"
                  >
                    <h3
                      class="mx-auto text-blue-darken-4 mb-2"
                      style="width: 300px"
                    >
                      {{ card.title }}
                    </h3>
                    <v-card
                      style="
                        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
                        border-radius: 0px;
                      "
                      class="mx-auto"
                      width="300"
                      height="300"
                    >
                      <div class="img-cont">
                        <div class="cart clearfix animate-effect">
                          <div class="action">
                            <div class="card-desc-cont-mobile px-2 text-center">
                              <h2>{{ card.title }}</h2>
                              <p>{{ card.desc }}</p>
                              <v-btn
                                elevation="4"
                                style="
                                  background-color: #ffa42e;
                                  border-radius: 0;
                                  padding-left: 6px;
                                  padding-right: 6px;
                                  padding-top: 4px;
                                  padding-bottom: 4px;
                                  font-weight: 600;
                                  font-size: 12px;
                                "
                              >
                                <span class="text-black" style=""
                                  >VIEW PRICES</span
                                >
                              </v-btn>
                            </div>
                          </div>
                        </div>
                        <div class="overlay"></div>
                        <v-img
                          :src="card.img"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="300"
                          cover
                          class="img-item"
                        >
                          <template #placeholder>
                            <div class="skeleton" />
                          </template>
                        </v-img>
                      </div>
                    </v-card>
                  </div>
                </v-lazy>
              </v-col>
            </transition-group>
          </v-row>
        </template>
        <template v-if="!isSmall">
          <v-row class="trending__app__wrapper mt-2">
            <transition-group name="card-transition" mode="out-in">
              <v-col
                v-for="(card, i) in filteredItems"
                :key="i"
                xs="6"
                sm="6"
                md="4"
                cols="12"
                class="card"
              >
                <v-lazy :options="{ threshold: 0.5 }" min-height="250">
                  <div
                    class="trending__app d-flex flex-column justify-center mb-8"
                  >
                    <h3 class="text-blue-darken-4 mb-2">{{ card.title }}</h3>
                    <v-card
                      style="
                        box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.15);
                        border-radius: 10px;
                      "
                      width="350"
                      height="250"
                    >
                      <div class="img-cont">
                        <div class="cart clearfix animate-effect">
                          <div class="action">
                            <div class="card-desc-cont px-2 text-center">
                              <h2>{{ card.title }}</h2>
                              <p>{{ card.desc }}</p>
                              <v-btn
                                elevation="4"
                                style="
                                  background-color: #ffa42e;
                                  border-radius: 0;
                                  padding-left: 16px;
                                  padding-right: 16px;
                                  padding-top: 10px;
                                  padding-bottom: 10px;
                                "
                              >
                                <span class="text-black" style=""
                                  >VIEW PRICES</span
                                >
                              </v-btn>
                            </div>
                          </div>
                        </div>
                        <div class="overlay"></div>
                        <v-img
                          :src="card.img"
                          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                          height="250"
                          cover
                          class="img-item"
                        >
                          <template #placeholder>
                            <div class="skeleton" />
                          </template>
                        </v-img>
                      </div>
                    </v-card>
                  </div>
                </v-lazy>
              </v-col>
            </transition-group>
          </v-row>
        </template>
      </v-container>
    </template>
  </div>
</template>

<script>
import axios from '@/util/axios';
import app from '@/util/eventBus';
import { mapState } from 'vuex';

export default {
  name: 'OurBooze',
  data() {
    return {
      isLoading: false,
      selectedTag: null,
      brandsCard: [],
      brand: null,
      country: null,
      resource: {
        brand: [],
        country: [],
      },
      title: '',
      screenWidth: window.innerWidth,
    };
  },
  // watch: {
  //   country: function () {
  //     const filtered = this.brandsCard.filter((item) => {
  //       return item.country.country_name
  //         .toLowerCase()
  //         .includes(this.country.toLowerCase());
  //     });
  //     this.brandsCard = filtered;
  //   },
  //   brand: function () {
  //     const filtered = this.brandsCard.filter((item) => {
  //       return item.brand_name.toLowerCase().includes(this.brand.toLowerCase());
  //     });
  //     this.brandsCard = filtered;
  //   },
  // },
  computed: {
    ...mapState(['activeTag']),
    isSmall() {
      return this.screenWidth < 640;
    },
    filteredItems() {
      if (this.brand === null && this.country === null) {
        return this.brandsCard;
      } else if (this.brand !== null && this.country === null) {
        const filteredData = this.brandsCard.filter((item) => {
          return this.brand
            ? item.brand_name.toLowerCase() === this.brand.toLowerCase()
            : true;
        });
        return filteredData;
      } else if (this.country !== null && this.brand === null) {
        const filteredData = this.brandsCard.filter((item) => {
          return this.country
            ? item.country.country_name.toLowerCase() ===
                this.country.toLowerCase()
            : true;
        });
        return filteredData;
      } else {
        const filteredData = this.brandsCard.filter((item) => {
          return (
            (this.brand
              ? item.brand_name.toLowerCase() === this.brand.toLowerCase()
              : true) &&
            (this.country
              ? item.country.country_name.toLowerCase() ===
                this.country.toLowerCase()
              : true)
          );
        });
        return filteredData;
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.getBrandsCardData();
    app.config.globalProperties.$eventBus.$on(
      'scrollToCardSection',
      this.scrollToCardSection
    );
  },
  beforeUnmount() {
    app.config.globalProperties.$eventBus.$off(
      'scrollToCardSection',
      this.scrollToCardSection
    );
    // eventBus.off("filter-card-header", this.filterCards);
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    scrollToCardSection() {
      const cardSection = document.getElementById('trending');
      const cardRect = cardSection.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const offset = cardRect.top + scrollTop - 350; // Nilai offset yang diinginkan, dalam piksel

      window.scrollTo({
        top: offset,
        behavior: 'smooth',
      });
      // window.scrollBy(0, -scrollOffset);
    },
    getBrandsCardData() {
      this.isLoading = true;
      axios
        .get(`/brands/${this.$appId}`)
        .then((response) => {
          const data = response.data.data;
          // console.log(data);
          const pathname = this.$route.path.split('ourbrands-')[1];
          const path = pathname.split('-').join(' ');
          this.brandsCard = data
            .filter(
              (item) => item.category.category_name.toLowerCase() === path
            )
            .map((item) => {
              return {
                ...item,
                id: item.brand_id || 1,
                img: this.$fileURL + item.image || '',
                desc: item.description || '',
                title: item.brand_name || '',
                category: item.category.category_name || '',
              };
            });
          console.log(this.brandsCard);
          this.title = this.brandsCard[0].category;
          this.resource.brand = [
            ...new Set(this.brandsCard.map((item) => item.brand_name)),
          ];
          this.resource.country = [
            ...new Set(
              this.brandsCard.map((item) => item.country.country_name)
            ),
          ];
          console.log(this.resource.brand);
          console.log(this.resource.country);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.line-divider {
  height: 2px;
  width: 150px;
  background: #ffa42e;
}

.title-card {
  position: absolute;
  top: -20px;
  width: 80%;
  color: white;
  background-color: #ffa42e;
  padding: 10px auto;
  z-index: 100;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border-radius: 30px;
}
.title-card-mobile {
  width: 70%;
  font-size: 12px !important;
}

.card-desc-cont h2 {
  color: white;
}
.card-desc-cont p {
  color: white;
  margin-bottom: 10px;
}
.card-desc-cont-mobile h2 {
  font-size: 16px;
  color: white;
}
.card-desc-cont-mobile p {
  font-size: 12px;
  color: white;
  margin-bottom: 10px;
}
.my-slide {
  position: relative !important;
}
.view-all {
  background: #0596d5;
  color: white;
  height: 50px !important;
  z-index: 100 !important;
  /* Gaya view all yang sticky */
}
.card-transition-enter-active,
.card-transition-leave-active {
  transition: transform 0.5s, opacity 0.3s;
}

.card-transition-enter {
  opacity: 0;
  transform: translateX(-50%);
}

.card-transition-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.img-item {
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  transform: scale(1);
}

.trending__app {
  position: relative;
}
.trending__app:hover .title-card {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100;
}

.trending__app:hover .overlay {
  opacity: 1;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;

  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

.desktop-card-desc {
  height: 70px !important;
}

.btn-section {
  background-color: #ffa42e;
  border-color: #ffa42e;
  font-weight: 400;
  font-size: 20px;
  border-radius: 50px;
}
.btn-section-2 {
  font-size: 14px;
}

.loading-page {
  height: 100vh;
  margin-top: 400px;
}

@media (max-width: 599px) {
  .loading-page {
    margin-top: 450px;
  }
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
