<template>
  <div>
    <div v-if="isLoading" class="text-center loading-page">
      <v-progress-circular :size="50" color="#fa2964" indeterminate />
    </div>
    <template v-if="!isLoading">
      <v-container :class="{ 'mt-16': isSmall }">
        <div v-if="!isSmall" id="trending-section" class="jumbotron-text">
          <h1
            align="center"
            class="header-title mb-n10 mt-16"
            style="font-size: 56px; font-style: normal; font-weight: 700"
          >
            OUR {{ brandsCard[0]?.category }}
          </h1>
          <div class="line-divider mt-10 mx-auto" />
          <!-- <p class="header-title-sub" style="margin-top: 32px">
          The gypsy Trending Web Apps
          </p> -->
        </div>
        <v-row v-if="!isSmall" class="d-flex justify-center">
          <v-col cols="12" md="3">
            <v-autocomplete
              density="compact"
              variant="outlined"
              v-model="brand"
              label="---Select Brand---"
              :items="resource.brands"
              clearable
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-container>

      <v-container
        id="trending"
        class="wrapper-box"
        :class="{ 'mt-16 pt-14': isSmall, 'mt-10': !isSmall }"
      >
        <template v-if="isSmall">
          <div
            v-for="item in filteredItems"
            :key="item.id"
            class="mb-4"
            style="border-bottom: 1px solid black"
          >
            <div class="d-flex justify-start mb-6">
              <div class="img-cont-2 mr-6">
                <v-img :src="item.image" height="70" cover>
                  <template #placeholder>
                    <div class="skeleton" />
                  </template>
                </v-img>
              </div>
              <div>
                <h4 class="mb-2">
                  {{ item.brand }}
                </h4>
                <v-btn
                  :to="`/${item.path}`"
                  :height="isSmall ? 35 : 50"
                  class="text-white elevation-2"
                  style="
                    background-color: #ffa42e;
                    border-color: #ffa42e;
                    font-size: 12px;
                    font-weight: 700;
                  "
                  elevation="2"
                >
                  <span>VIEW PRICES</span>
                </v-btn>
              </div>
            </div>
            <div class="d-flex align-center" style="gap: 8px">
              <div class="w-100">
                <div
                  class="d-flex w-100 mb-2"
                  v-for="product in item.products"
                  :key="product.id"
                >
                  <div
                    class="d-flex w-100 justify-space-between align-center"
                    style="gap: 15px"
                  >
                    <h5>{{ `${product.product} - ${product.quantity}` }}</h5>
                    <div class="d-flex" style="gap: 10px">
                      <div>
                        <h6>Lowest</h6>
                        <span style="font-size: 10px" class="text-red mt-4"
                          >S$64.50</span
                        >
                      </div>
                      <div>
                        <h6>Highest</h6>
                        <span style="font-size: 10px" class="text-blue mt-4"
                          >S$72.80</span
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="!isSmall">
          <v-row v-for="item in filteredItemsDesktop" :key="item.id">
            <v-col cols="3">
              <h1>
                {{ item.brand }}
              </h1>

              <div class="img-cont mt-4">
                <v-img :src="item.image" height="200" cover>
                  <template #placeholder>
                    <div class="skeleton" />
                  </template>
                </v-img>
              </div>
            </v-col>
            <v-col cols="9">
              <div class="d-flex align-center justify-space-between mt-14">
                <div>
                  <div
                    class="d-flex mb-4"
                    v-for="product in item.products"
                    :key="product.id"
                  >
                    <div class="d-flex align-center" style="gap: 40px">
                      <h4>{{ `${product.product} - ${product.quantity}` }}</h4>
                      <div class="d-flex" style="gap: 10px">
                        <div>
                          <h6>Lowest</h6>
                          <span class="text-red mt-4">S$ 64.50</span>
                        </div>
                        <div>
                          <h6>Highest</h6>
                          <span class="text-blue mt-4">S$ 72.80</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <v-btn
                  :to="`/${item.path}`"
                  :height="isSmall ? 35 : 50"
                  class="text-white elevation-2"
                  style="
                    background-color: #ffa42e;
                    border-color: #ffa42e;
                    font-size: 20px;
                    font-weight: 700;
                  "
                  elevation="2"
                >
                  <span class="mr-4">VIEW PRICE LIST</span>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-container>
    </template>
  </div>
</template>

<script>
import axios from '@/util/axios';
import { mapState, mapMutations } from 'vuex';
import app from '@/util/eventBus';

export default {
  name: 'OurBoozeDetail',
  data() {
    return {
      isLoading: false,
      selectedTag: null,
      brandsCard: [],
      screenWidth: window.innerWidth,
      brand: null,
      resource: {
        brands: [],
      },
    };
  },
  // watch: {
  //   // eslint-disable-next-line no-unused-vars
  //   brand: function (newBrand, oldBrand) {
  //     this.setActiveTag(newBrand);
  //     this.$store.commit('setActiveTag', newBrand);
  //   },
  // },
  computed: {
    ...mapState(['activeTag']),
    isSmall() {
      return this.screenWidth < 640;
    },
    filteredItems() {
      console.log(this.activeTag);
      if (!this.activeTag || this.activeTag == undefined) {
        return this.brandsCard;
      } else {
        // const searchTextLower = this.search.toLowerCase();
        return this.brandsCard.filter((brand) =>
          brand.brand.includes(this.activeTag)
        );
        // .map((b) => {
        //   return {
        //     ...b,
        //     list: b.list.filter((item) => {
        //       return item.tag.includes(this.activeTag);
        //     }),
        //   };
        // });
      }
    },
    filteredItemsDesktop() {
      if (this.brand == null) {
        return this.brandsCard;
      } else {
        // const searchTextLower = this.search.toLowerCase();
        return this.brandsCard.filter((brand) =>
          brand.brand.includes(this.brand)
        );
      }
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.getBrandsCardData();
    this.getDetailCard();
    // app.config.globalProperties.$eventBus.$o(
    //   'filterBoozeDetail',
    //   this.get
    // );
  },
  // beforeUnmount() {
  //   this.setActiveTag('');
  //   this.$store.commit('setActiveTag', '');
  // },
  unmounted() {
    this.setActiveTag('');
    this.$store.commit('setActiveTag', '');
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    ...mapMutations(['setActiveTag']),
    getDetailCard() {
      this.brand = null;
      app.config.globalProperties.$eventBus.$emit('getDetailCard');
    },
    getBrandsCardData() {
      const slug = this.$route.params.name;
      this.isLoading = true;
      axios
        .get(`/brands-products/category/${slug}`)
        .then((response) => {
          const data = response.data.data;
          this.brandsCard = data.map((item) => {
            return {
              id: item.brand_id || 1,
              brand: item.brand_name || '',
              category: item.category_name || '',
              image: this.$fileURL + item.image || '',
              path: item.brand_name.split(' ').join('-').toLowerCase() || '',
              products: item.products.map((product) => {
                return {
                  id: product.product_id || '',
                  product: product.product_name || '',
                  quantity: product.quantity[0]?.quantity_name || '',
                };
              }),
            };
          });
          this.resource.brands = data.map((item) => item.brand_name);
          this.$store.commit('setSlugSelected', this.brandsCard[0].category);
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>

<style scoped>
.line-divider {
  height: 2px;
  width: 150px;
  background: #ffa42e;
}

.title-card {
  position: absolute;
  top: -20px;
  width: 80%;
  color: white;
  background-color: #ffa42e;
  padding: 10px auto;
  z-index: 100;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  border-radius: 30px;
}
.title-card-mobile {
  width: 70%;
  font-size: 12px !important;
}

.card-desc-cont h2 {
  color: white;
}
.card-desc-cont p {
  color: white;
  margin-bottom: 10px;
}
.card-desc-cont-mobile h2 {
  font-size: 16px;
  color: white;
}
.card-desc-cont-mobile p {
  font-size: 12px;
  color: white;
  margin-bottom: 10px;
}
.my-slide {
  position: relative !important;
}
.view-all {
  background: #0596d5;
  color: white;
  height: 50px !important;
  z-index: 100 !important;
  /* Gaya view all yang sticky */
}
.card-transition-enter-active,
.card-transition-leave-active {
  transition: transform 0.5s, opacity 0.3s;
}

.card-transition-enter {
  opacity: 0;
  transform: translateX(-50%);
}

.card-transition-leave-to {
  opacity: 0;
  transform: scale(0.8);
}

.img-item {
  transition: all 0.3s;
  width: 100%;
  height: 100%;
  transform: scale(1);
}

.img-cont {
  position: relative;
  width: 250px;
  height: 250px;
}
.img-cont-2 {
  position: relative;
  width: 70px;
  height: 70px;
}
.img-cont:hover .title-card {
  display: none;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
  z-index: 100;
}

.img-cont:hover .overlay {
  opacity: 1;
}

.skeleton {
  width: 100%;
  height: 100%;
  border-radius: 0;

  background: linear-gradient(-90deg, #f2f2f2 0%, #e1e1e1 50%, #f2f2f2 100%);
  background-size: 400% 400%;
  animation: skeleton 1.6s ease infinite;
}

.desktop-card-desc {
  height: 70px !important;
}

.btn-section {
  background-color: #ffa42e;
  border-color: #ffa42e;
  font-weight: 400;
  font-size: 20px;
  border-radius: 50px;
}
.btn-section-2 {
  font-size: 14px;
}

.loading-page {
  height: 100vh;
  margin-top: 400px;
}

@media (max-width: 599px) {
  .loading-page {
    margin-top: 450px;
  }
}

@keyframes skeleton {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: -100% 0;
  }
}
</style>
